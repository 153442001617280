<template>
    <div id="cliniques-index" class="page-wrapper" v-if="pageIsLoaded">
        <!-- Hero -->
        <section class="small-hero">
            <div class="col col-content">
                <h1 class="regular-title" data-inview="fadeInUp" data-delay="100">
                    {{ $t('Nos cliniques') }}
                </h1>
            </div>
            <div class="col col-img" data-inview="revealRight" data-delay="100">
                <img :src="content.image.url" :alt="content.image.titre" />
            </div>
        </section>

        <!-- Infos Grid -->
        <section class="infos-grid">
            <div
                class="col"
                data-inview="fadeInUp"
                :data-delay="200 * (i + 1)"
                v-for="(item, i) in cliniquesItems"
                :key="i"
            >
                <h2 class="regular-title">{{ item.title }}</h2>
                <a :href="'tel:' + item.telephone" class="tel">{{ item.telephone }}</a>
                <p class="small-text">
                    <span v-html="item.adresse.long"></span>
                </p>
                <router-link :to="item.jsonUrl" title="Soins offerts" class="site-btn alternate2"
                    >{{ $t('Soins offerts') }}</router-link
                >
            </div>
        </section>

        <!-- Notre equipe -->
        <section class="notre-equipe">
            <div class="col col-content">
                <h2 class="small-title" data-inview="fadeInUp" data-delay="100">
                    <span v-html="$t('Rencontrer <br /> nos dentistes')"></span>
                </h2>
                <p class="regular-text" data-inview="fadeInUp" data-delay="300">
                    {{ $t("Une équipe de professionnels passionés et dévoués pour vous accompagner tout au long de votre expérience au sein de notre clinique.") }}
                </p>
                <div data-inview="fadeInUp" data-delay="400">
                    <router-link
                        :to="{ name: 'TraitementsIndex-' + $route.meta.lang }"
                        class="site-btn alternate"
                        title="Découvrez nos traitements"
                    >{{ $t('Découvrez nos traitements') }}</router-link>
                </div>
            </div>
            <div class="col col-slider">
                <div class="grid carousel-team" data-inview="fadeInUp" data-delay="600">
                    <router-link
                        :to="item.poste === 'Dentiste' || item.poste === 'Dentist' ? item.jsonUrl : ''"
                        class="team-thumb carousel-cell"
                        title=""
                        v-for="(item, i) in globals.equipeMembres.data"
                        :key="i"
                    >
                        <div class="img-wrap">
                            <img :src="item.image.url" :alt="item.image.titre" />
                            <svg
                                class="plus"
                                height="45"
                                viewBox="0 0 60 60"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                v-if="item.poste === 'Dentiste' || item.poste === 'Dentist'"
                            >
                                <circle cx="30" cy="30" r="30" fill="white" />
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M30 40C30.9266 40 31.6931 39.2335 31.6931 38.3069V31.6931H38.3069C39.2335 31.6931 40 30.9266 40 30C40 29.0734 39.2335 28.3069 38.3069 28.3069H31.6931V21.6931C31.6931 20.7665 30.9266 20 30 20C29.0734 20 28.3069 20.7665 28.3069 21.6931V28.3069H21.6931C20.7665 28.3069 20 29.0734 20 30C20 30.9266 20.7665 31.6931 21.6931 31.6931H28.3069V38.3069C28.3069 39.2335 29.0734 40 30 40V40Z"
                                    fill="#565B6E"
                                />
                            </svg>
                        </div>
                        <div class="content">
                            <p class="regular-text title">{{ item.title }}</p>
                            <p class="small-text text">{{ item.poste }}</p>
                        </div>
                    </router-link>
                </div>
            </div>
        </section>

        <!-- Team grid -->
        <!-- <section class="team-grid">
            <div class="section-heading-n-btn">
                <h2 class="regular-title" data-inview="fadeInUp" data-delay="100">
                    <span v-html="$t('Rencontrer <br /> nos dentistes')"></span>
                </h2>
            </div>
            <div class="grid">
                <router-link
                    :to="item.jsonUrl"
                    class="team-thumb"
                    title=""
                    data-inview="fadeInUp"
                    data-delay="200"
                    v-for="(item, i) in equipeDentistes"
                    :key="i"
                >
                    <div class="img-wrap">
                        <img :src="item.image.url" :alt="item.image.titre" />
                        <svg
                            class="plus"
                            height="45"
                            viewBox="0 0 60 60"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle cx="30" cy="30" r="30" fill="white" />
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M30 40C30.9266 40 31.6931 39.2335 31.6931 38.3069V31.6931H38.3069C39.2335 31.6931 40 30.9266 40 30C40 29.0734 39.2335 28.3069 38.3069 28.3069H31.6931V21.6931C31.6931 20.7665 30.9266 20 30 20C29.0734 20 28.3069 20.7665 28.3069 21.6931V28.3069H21.6931C20.7665 28.3069 20 29.0734 20 30C20 30.9266 20.7665 31.6931 21.6931 31.6931H28.3069V38.3069C28.3069 39.2335 29.0734 40 30 40V40Z"
                                fill="#565B6E"
                            />
                        </svg>
                    </div>
                    <div class="content">
                        <p class="regular-text title">{{ item.title }}</p>
                        <p class="small-text text">{{ item.poste }}</p>
                    </div>
                </router-link>
            </div>
        </section> -->
    </div>
    <PageLoader v-else />
</template>

<script>
import Flickity from 'flickity'

import { initInViewAnimations } from '@/plugins/InViewAnimations'
import { mapState, mapGetters } from 'vuex'
import PageLoader from '@/components/PageLoader.vue'

export default {
    name: 'Cliniques',

    data() {
        return {
            teamCarouselFlk: null
        }
    },

    components: {
        PageLoader,
    },

    computed: {
        cliniquesItems() {
            if (!this.globalsIsLoaded) {
                return []
            }
            return this.globals.cliniquesItems.data
        },
        equipeDentistes() {
            if (!this.globalsIsLoaded) {
                return []
            }
            return this.globals.equipeMembres.data.filter(i => i.poste === 'Dentiste')
        },
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    setTimeout(() => {
                        initInViewAnimations()

                        const teamCarousel = document.querySelector('.carousel-team')
                        this.teamCarouselFlk = new Flickity(teamCarousel, {
                            cellAlign: 'left',
                            wrapAround: true,
                            lazyLoad: true,
                            imagesLoaded: true,
                            prevNextButtons: true,
                            pageDots: false,
                        })

                        setTimeout(() => {
                            this.teamCarouselFlk.resize()

                            setTimeout(() => {
                                this.teamCarouselFlk.resize()
                            }, 1200)
                        }, 1200)
                    }, 155)
                }
            },
            immediate: true,
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.page-wrapper {
    min-height: 90vh;
}
</style>
